import React from 'react'
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import ArgonButton from "components/ArgonButton";
import Rating from "../Rating"
import { formatTimestamp, countryToLocale } from 'blikol-layouts/clinic/parsers/dateFormatParser';
import PropTypes from 'prop-types';
import "utils/i18n"
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { formatTimestamp as adminTimestampFormat, formatDate as adminDateFormat } from 'parsers/formatParser';

function SessionHistory({ onBackButtonClick, duration, frequency, feedbacks }) {
    const userCountry = useSelector((state) => state.auth?.user?.country);
    const { t } = useTranslation()
    const userRole = useSelector((state) => state.auth?.user?.role);

    const fieldNameStyle = {
        color: 'var(--Label, #8392AB)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
    }

    const fieldValueStyle = {
        color: "var(--Text, #1A1D1F)",
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
        marginTop: "6px"

    }

    const frequencyFormat = (value) => {
        return `${value} Hz`;
    };

    const durationFormat = (value) => {
        if (value === 1) {
            return `${value} min`;

        } else {
            return `${value} mins`;
        }

    };

    return (
        <Card>
            <ArgonBox sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                pt={5}
                px={4}
            >
                <ArgonTypography
                    sx={{
                        color: 'var(--Default, #172B4D)',
                        fontFamily: 'Open Sans',
                        fontSize: '25px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '137%', // Converted from "137%"
                        letterSpacing: '-0.8px',
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    {t("Session History")}
                </ArgonTypography>
                <ArgonBox display="flex" alignItems="center" >
                    <ArgonButton
                        variant="text"
                        color="info"
                        sx={{ fontWeight: 600 }}
                        onClick={onBackButtonClick}
                    >
                        {t("Back")}
                    </ArgonButton>
                </ArgonBox>

            </ArgonBox>

            <Grid item xs={12} sm={12} p={3}>
                {
                    feedbacks?.map((feedback, index) => (
                        <Card
                            key={index}
                            sx={{
                                padding: '30px',
                                gap: '10px',
                                borderRadius: '20px',
                                background: '#F4F2F2',
                                marginBottom: "25px",
                            }}
                        >
                            <ArgonBox
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                            >
                                <ArgonTypography
                                    sx={{
                                        color: '#172B4D',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: 700,
                                        lineHeight: '150%',

                                    }}
                                >
                                    {userRole === ('admin' || 'super_admin' || 'secretary')
                                        ? adminTimestampFormat(feedback.matured_at !== null ? feedback.matured_at : feedback.created_at)
                                        : formatTimestamp(feedback.matured_at !== null ? feedback.matured_at : feedback.created_at, countryToLocale[userCountry])
                                    }
                                    {console.log(feedback.id, feedback.matured_at)}
                                </ArgonTypography>
                                <ArgonTypography
                                    color={feedback.matured_at === null ? "error" : "success"}
                                    sx={{
                                        fontSize: 14,
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "137%",
                                        letterSpacing: "-0.8px",
                                        textTransform: "capitalize"
                                    }}
                                >
                                    {feedback.matured_at === null ? t("incomplete") : t("complete")}
                                </ArgonTypography>

                            </ArgonBox>


                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Grid item xs={6}>
                                        <ArgonBox
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column"
                                            }}
                                        >
                                            <ArgonTypography sx={fieldNameStyle}>{t("Frequency")}</ArgonTypography>
                                            <ArgonTypography sx={fieldValueStyle}>{frequencyFormat(frequency)}</ArgonTypography>

                                        </ArgonBox>
                                    </Grid>
                                    {/* <ArgonBox
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <ArgonTypography sx={fieldNameStyle}>{t("Session Duration")}</ArgonTypography>
                                        <ArgonTypography sx={fieldValueStyle}>{durationFormat(duration)}</ArgonTypography>

                                    </ArgonBox> */}
                                </Grid>

                                {/* <Grid item xs={4}>
                                    <ArgonBox
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <ArgonTypography sx={fieldNameStyle}>Feedback</ArgonTypography>
                                        <Rating points={feedback?.rating} />
                                    </ArgonBox>
                                </Grid> */}
                            </Grid>

                        </Card>
                    ))
                }

            </Grid>

        </Card>

    )
}

SessionHistory.propTypes = {
    onBackButtonClick: PropTypes.func.isRequired,
    duration: PropTypes.any,
    frequency: PropTypes.any,
    feedbacks: PropTypes.any
};

export default SessionHistory